<script>
import { SuawForm, SuawHeading, SuawTextInput, SuawInputGroup, SuawLabel, SuawRadio, SuawSelect, SuawButton, SuawParagraph, SuawFileUpload } from "@/components";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import CircleStencil from "@/components/CircleStencil.vue";
import { ListCountries } from "../../operations.gql";
import { usStates, canadianProvinces } from "../../../../utils/geo/regions.js";
import * as UserApi from "../../api";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
export default {
  name: "CompleteAccountForm",
  components: {
    SuawForm,
    SuawHeading,
    SuawTextInput,
    SuawInputGroup,
    SuawLabel,
    SuawRadio,
    SuawSelect,
    SuawButton,
    SuawParagraph,
    SuawFileUpload,
    Cropper
  },
  data() {
    return {
      countries: [],
      loadingCountries: true,
      completeAccountForm: {
        firstName: "",
        lastName: "",
        countryAbbrev: "",
        isLastNameHidden: true,
        city: "",
        region: "",
        postalCode: "",
        avatarUrl: ""
      },
      showCropper: false,
      cropperImage: null,
      croppedPreviewUrl: null
    };
  },
  apollo: {
    countries: {
      query: ListCountries,
      result() {
        this.loadingCountries = false; // Mark loading as complete
      }
    }
  },
  validations() {
    return {
      completeAccountForm: {
        firstName: {
          required,
          maxLength: maxLength(40)
        },
        lastName: {
          required,
          maxLength: maxLength(40)
        },
        countryAbbrev: {
          required
        },
        city: {
          required
        },
        region: {
          required: requiredIf(() => {
            return this.completeAccountForm.countryAbbrev === "US" || this.completeAccountForm.countryAbbrev === "CA";
          })
        },
        postalCode: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(10)
        }
      }
    };
  },
  computed: {
    formattedCountries() {
      return this.countries.map(country => ({
        text: country.name,
        value: country.abbrev,
        key: country.abbrev
      }));
    },
    regionOptions() {
      if (this.completeAccountForm.countryAbbrev === "US") {
        return usStates;
      } else if (this.completeAccountForm.countryAbbrev === "CA") {
        return canadianProvinces;
      }
      return [];
    },
    radioLabels() {
      const { firstName, lastName } = this.completeAccountForm;
      const lastInitial = lastName ? `${lastName.charAt(0).toUpperCase()}` : "";
      return {
        firstLast: firstName && lastInitial ? `${firstName} ${lastInitial}` : "First Name & Last Initial",
        fullName: firstName && lastName ? `${firstName} ${lastName}` : "Full Name"
      };
    },
    circleStencilComponent() {
      return CircleStencil;
    }
  },
  mounted() {
    if (this.$auth.user && this.$auth.user.email_verified) {
      this.completeAccountForm.firstName = this.$auth.user.first_name;
      this.completeAccountForm.lastName = this.$auth.user.last_name;
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    async onFileSelected(file) {
      try {
        const reader = new FileReader();
        reader.onload = e => {
          this.cropperImage = e.target.result;
          this.showCropper = true;
        };
        reader.readAsDataURL(file);
      } catch (error) {
        this.$root.$emit("universal-error-message", "Error processing image");
      }
    },

    cancelCrop() {
      this.showCropper = false;
      this.cropperImage = null;
      this.croppedPreviewUrl = null;
      this.completeAccountForm.avatarUrl = "";
    },

    onResizeStart() {
      // Just a placeholder for the event
    },

    onResize(event) {
      if (event.directions) {
        // The directions have already been fixed in the CircleStencil component
        // We don't need to modify them further
        return;
      }

      // Default handling for standard resize events
      const size = Math.min(event.width, event.height);
      event.width = size;
      event.height = size;
    },

    onResizeEnd() {
      // Just a placeholder for the event
    },

    completeCrop() {
      try {
        const result = this.$refs.cropper.getResult({
          size: "stencil",
          fillColor: null
        });

        if (result?.canvas) {
          const canvas = result.canvas;
          const ctx = canvas.getContext("2d");
          const size = canvas.width;

          // Apply circular mask
          ctx.globalCompositeOperation = "destination-in";
          ctx.beginPath();
          ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
          ctx.closePath();
          ctx.fill();

          // Get the cropped canvas as a base64 string
          const croppedResult = canvas.toDataURL("image/jpeg");
          // Convert data URL to base64 string (remove the prefix)
          const base64Data = croppedResult.split(",")[1];
          this.completeAccountForm.avatarUrl = base64Data;
          this.croppedPreviewUrl = croppedResult;
          this.showCropper = false;
        }
      } catch (error) {
        this.$root.$emit("universal-error-message", "Error in completeCrop: " + error);
      }
    },
    async UploadUserAvatar() {
      const { avatarUrl } = this.completeAccountForm;
      const result = await UserApi.uploadUserAvatar(this.$auth.user.id, avatarUrl);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async completeRegistration() {
      const { firstName, lastName, isLastNameHidden, countryAbbrev, postalCode, city, region } = this.completeAccountForm;
      const result = await UserApi.completeRegistration(this.$auth.user.id, firstName, lastName, isLastNameHidden, countryAbbrev, postalCode, city, region);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onAccountCompleted() {
      this.$v.completeAccountForm.$touch();
      if (this.$v.completeAccountForm.$invalid) {
        this.$root.$emit("universal-error-message", "Please complete the form to finish your account registration.");
        return;
      }
      const completeRegistrationResult = await this.completeRegistration();
      if (!completeRegistrationResult.success) {
        return;
      }

      if (this.completeAccountForm.avatarUrl) {
        const uploadUserAvatarResult = await this.UploadUserAvatar();
        if (!uploadUserAvatarResult.success) {
          return;
        }
      }

      this.$emit("submit");
    }
  }
};
</script>
<template>
  <SuawForm>
    <template #form>
      <SuawHeading level="3" content="Complete Your Account" alignment="center" />
      <SuawInputGroup no-wrap>
        <SuawTextInput
          id="first-name-complete-account"
          v-model="completeAccountForm.firstName"
          type="text"
          placeholder="First Name"
          is-required
          label="First Name"
          :state="formFieldState($v, 'completeAccountForm', 'firstName')"
          :error-message="validateErrors($v.completeAccountForm.firstName, 'First name')"
          @blur="$v.completeAccountForm.firstName.$touch()"
        />
        <SuawTextInput
          id="last-name-complete-account"
          v-model="completeAccountForm.lastName"
          type="text"
          placeholder="Last Name"
          is-required
          label="Last Name"
          :state="formFieldState($v, 'completeAccountForm', 'lastName')"
          :error-message="validateErrors($v.completeAccountForm.lastName, 'Last name')"
          @blur="$v.completeAccountForm.lastName.$touch()"
        />
      </SuawInputGroup>
      <SuawInputGroup direction="column" group-gap="base">
        <SuawLabel label-text="Display Name" weight="bold" size="small" />
        <SuawRadio id="firstLast" v-model="completeAccountForm.isLastNameHidden" name="chooseName" :label="radioLabels.firstLast" :native-value="true" />
        <SuawRadio id="full" v-model="completeAccountForm.isLastNameHidden" name="chooseName" :label="radioLabels.fullName" :native-value="false" />
      </SuawInputGroup>
      <SuawSelect
        v-if="!loadingCountries"
        v-model="completeAccountForm.countryAbbrev"
        label="Country"
        placeholder="Select your country"
        :is-required="completeAccountForm.countryAbbrev === 'US' || completeAccountForm.countryAbbrev === 'CA'"
        :options="formattedCountries"
        :state="formFieldState($v, 'completeAccountForm', 'countryAbbrev')"
        :error-message="validateErrors($v.completeAccountForm.countryAbbrev, 'Country')"
        success-message=""
        @blur="$v.completeAccountForm.countryAbbrev.$touch()"
      />
      <SuawSelect
        v-if="completeAccountForm.countryAbbrev === 'US' || completeAccountForm.countryAbbrev === 'CA'"
        id="region"
        v-model="completeAccountForm.region"
        :options="regionOptions"
        is-required
        :state="formFieldState($v, 'completeAccountForm', 'region')"
        :error-message="validateErrors($v.completeAccountForm.region, `${completeAccountForm.countryAbbrev === 'US' ? 'State' : 'Province'}`)"
        :placeholder="completeAccountForm.countryAbbrev === 'US' ? 'Select your state' : 'Select your province'"
        :label="completeAccountForm.countryAbbrev === 'US' ? 'State' : 'Province'"
        success-message=""
        label-weight="bold"
      />
      <SuawTextInput
        id="city-complete-account"
        v-model="completeAccountForm.city"
        type="text"
        label="City"
        is-required
        :state="formFieldState($v, 'completeAccountForm', 'city')"
        :error-message="validateErrors($v.completeAccountForm.city, 'City')"
      />
      <SuawTextInput
        id="postal-code-complete-account"
        v-model="completeAccountForm.postalCode"
        type="text"
        is-required
        :label="completeAccountForm.countryAbbrev === 'US' ? 'Zip Code' : 'Postal code'"
        :state="formFieldState($v, 'completeAccountForm', 'postalCode')"
        :error-message="validateErrors($v.completeAccountForm.postalCode, 'Postal code')"
        @blur="$v.completeAccountForm.postalCode.$touch()"
      />
      <SuawInputGroup direction="column" group-gap="half">
        <SuawLabel weight="bold" size="small" label-text="Profile Picture (Optional)" />
        <SuawFileUpload upload-title="Upload Profile Picture" @file-selected="onFileSelected" />
        <div v-if="croppedPreviewUrl" class="preview-container">
          <SuawLabel label-text="Preview" size="small" weight="bold" />
          <img :src="croppedPreviewUrl" class="preview-image" />
        </div>
      </SuawInputGroup>

      <!-- Image cropper modal -->
      <div v-if="showCropper" class="cropper-modal">
        <div class="cropper-container">
          <Cropper
            ref="cropper"
            class="cropper"
            image-restriction="stencil"
            :src="cropperImage"
            :stencil-component="circleStencilComponent"
            :stencil-props="{
              aspectRatio: 1,
              movable: true,
              resizable: true,
              stencilSize: { width: 300, height: 300 }
            }"
            @resize-start="onResizeStart"
            @resize="onResize"
            @resize-end="onResizeEnd"
          />
          <div class="cropper-actions">
          <SuawButton type="secondary-outline" button-text="Cancel" @click="cancelCrop" />
          <SuawButton type="primary" button-text="Crop" class="crop-button" @click="completeCrop" />
          </div>
        </div>
      </div>
      <SuawParagraph
        text="By clicking Finish Account, you accept the <a href='/resources/terms-of-service' target='_blank'>Terms of Service</a> and <a href='/resources/code-of-conduct' target='_blank'>Code of Conduct</a>."
      />
      <SuawButton size="large" type="primary" button-text="Finish Account" @click="onAccountCompleted" />
    </template>
  </SuawForm>
</template>

<style scoped>
.preview-container {
  margin-top: 20px;
  min-height: 150px;
}

.preview-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ccc;
}

.preview-placeholder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #999;
  text-align: center;
  padding: 10px;
}

.cropper-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow-y: auto;
}

.cropper-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}

.cropper {
  width: 100%;
  height: auto;
  max-height: 400px;
  margin-bottom: 20px;
  transform: translateZ(0);
}

.cropper-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

:deep(.vue-advanced-cropper__foreground) {
  background: transparent !important;
  opacity: 0 !important;
  content: none !important;
  display: none !important;
}

.crop-button {
  background-color: #376BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.crop-button:hover {
  background-color: #2f5ee0;
}
</style>
